<template>
    <div>
    <v-expansion-panels flat>
    <v-expansion-panel
    @click="showOrders(factor.factor_ref)"
      v-for="factor in factors" :key="factor.factor_id">
      <v-expansion-panel-header class="titleFont--text mt-1">
          <div class="d-flex flex-column">
          <span class="mb-2 medium-font">تاریخ سفارش</span>
          <span class="medium-font" v-text="factor.created_date" ></span>
          </div>
          <div class="d-flex flex-column mr-auto">
          <span class="mb-2 medium-font">تعداد مرسوله</span>
          <span class="mr-5 medium-font" v-text="factor.cargo_count"></span>
          </div>
          <!-- <div class="mr-auto">
          <v-icon size="18" color="success" v-show="factor.status == 6">mdi-check-underline-circle</v-icon>
          <v-icon size="18" color="error" v-show="show">mdi-alert-circle</v-icon>
          <v-icon size="18" color="warning" v-show="factor.status != 6">mdi-clock</v-icon>
          </div> -->
          <template v-slot:actions>
          <v-icon color="primary">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-skeleton-loader
        v-if="cargos.length < 1"
          v-bind="attrs"
          type="list-item-two-line"
        ></v-skeleton-loader>
       <v-list nav dense class="pa-0 pt-2">
        <v-list-item-group color="primary">
          <v-list-item v-for="(cargo, index) in cargos" :key="cargo.cargo_id" class="pr-1">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-truck-fast</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="show_cargo(cargo.cargo_id)">
              <v-list-item-title>جزئیات مرسوله <span v-text="index + 1"></span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="cargos.length > 0" @click="showOrdersDetail(factor.factor_ref)" class="pr-1">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-receipt</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>مشاهده فاکتور</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
    </div>
</template>
<script>
import router from '@/router'
import factors from '../../store/factors'
import { getcargos } from '../../models/factors'
import { mapState } from 'vuex'
export default {
   props: ['factors'],
   data: () => ({
      show: false,
      attrs: {
      boilerplate: false,
      elevation: 0,
  },
    }),
    computed: {
      ...mapState({
        cargos: state => state.factors.cargos
      })
    },
    created () {
      console.log(this.$props.factors)
    },
    methods: {
        showOrdersDetail(ref) {
          router.push(`/order_detail/${ref}`)
        },
        showOrders (ref) {
          getcargos(ref)
        },
        show_cargo (cargo_id) {
          router.push(`/orders/${cargo_id}`)
        }
    }
}
</script>